* {
  box-sizing: border-box;
}

html,
body {
  overflow-x: hidden;
  scroll-behavior: smooth;
}
body {
  margin: 0;
  padding: 0;
  background-color: #161616;
  font-family: Futura, sans-serif;
}
.main-section {
  margin: 0;
  padding: 1px 0 100px 0;

  background-repeat: repeat-y;
  background-size: 100vw;
  background-attachment: fixed;
  color: #ffffff;
}

.main {
  width: 900px;
  margin: 0 auto;
}
hr {
  // color: #333544;
  // size: 2px;
  border: 1px solid #333544;
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 50px;
  font-weight: bold;
  margin: 200px auto 100px auto;
  text-align: center;
}

h2 {
  font-size: 40px;
  font-weight: bold;
  margin: 0;
}
h3 {
  font-size: 30px;
  font-weight: 700;
  margin: 0;
}
.btn-container {
  text-align: center;
  margin: 50px auto 0 auto;
}
.page-link{
  text-decoration: none;
  color: #ffffff;
}
.btn {
  background-color: #db1300;
  box-shadow: 0px 12px 44px rgba(219, 19, 0, 0.3);
  border-radius: 2px;
  padding: 13px 20px;
  display: inline-block;
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  text-transform: uppercase;

  cursor: pointer;
  .fa-sync-alt {
    transition: all 0.5s ease-in-out;
  }
  &.btn1 {
    font-size: 30px;
    padding: 20px 60px;
  }

  &:hover {
    background-color: #fb1802;
    .fa-sync-alt {
      transform: rotate(60deg);
    }
  }

  &.spin {
    .fa-sync-alt {
      animation: 0.6s ease-in-out spin;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(60deg);
  }

  100% {
    transform: rotate(420deg);
  }
}

.dot {
  &::before {
    content: "";
    display: inline-block;
    width: 23px;
    height: 23px;
    border-radius: 6px;
    margin-right: 10px;
    background-color: #db1300;
  }
}

p {
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;
}

.text-content {
  margin-top: 60px;
  h1 {
    margin: 0;
    font-size: 40px;
    text-align: left;
    width: auto;
  }
  h2 {
    font-size: 35px;
  }
  ol {
    list-style: none;
    counter-reset: li;
    > li {
      font-size: 16px;
      line-height: 30px;
      font-weight: 400;
      margin-block-start: 1em;
      margin-block-end: 1em;

      &:before {
        counter-increment: li;
        content: counters(li, ".") ". ";
      }
    }
  }
  ul {
    li {
      margin-block-start: 1em;
      margin-block-end: 1em;
    }
  }

  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 2px solid #333544;
    &.text-column {
      flex-direction: column;
      padding: 50px 0;
    }
    > div {
      width: 47%;
      padding: 50px 0;
      .app-links {
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        img {
          padding: 5px;
        }
      }
    }
  }
  .img-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    &.mac {
      img {
        width: 100%;
      }
    }
    &.app {
      justify-content: flex-start;
      position: relative;
      .back_logo {
        margin: 65px 0 100px 0;

        width: 52%;
      }
      .iphone {
        position: absolute;
        top: 60px;
        left: 20%;
        height: 400px;
        border-radius: 40px;
        box-shadow: 27px 4px 58px 14px rgba(0, 0, 0, 0.24);
      }
    }
    &.support {
      img {
        width: 334px;
        height: 559px;
      }
    }
  }
}

.faq-container {
  padding: 60px 0;

  .rows {
    margin: 40px 0;
    .faq {
      margin: 15px 0;
      .question {
        background: #2b2e3a;
        min-height: 56px;

        border-radius: 6px;
        padding: 6px 20px;
        display: flex;
        align-items: center;
        cursor: pointer;
        h3 {
          width: 100%;
          font-size: 28px;

          &::before {
            content: "\002B";
            display: inline-block;
            height: 38px;
            font-size: 38px;
            line-height: 1;
            margin-right: 20px;
            vertical-align: top;
          }
        }
      }
      .answer {
        max-height: 0;
        overflow: hidden;
        transition: max-height ease-in-out 1s;

        p {
          margin: 20px 50px;
        }
        ol,
        ul {
          margin: 0 50px;
          li {
            font-size: 16px;
            line-height: 30px;
            font-weight: 400;
            margin-block-start: 1em;
            margin-block-end: 1em;
          }
        }
      }

      &.active {
        .question {
          h3 {
            &::before {
              content: "\2212";
            }
          }
        }
        .answer {
          max-height: 10000px;
        }
      }
    }
  }
}

@media screen and (max-width: 930px) {
  .main {
    width: 550px;
  }
  h3 {
    font-size: 20px;
  }
  .btn {
    &.btn1 {
      width: auto;
    }
  }

  .text-content {
    margin-top: 60px;

    > div {
      flex-direction: column;
      justify-content: flex-start;
      border-bottom: none;
      > div {
        width: 100%;
        padding: 30px 0;
        border-bottom: 2px solid #333544;
        img {
          width: 70%;
        }
        .app-links {
          img {
            padding: 5px;
            width: 100%;
            height: auto;
          }
        }
      }
      &.text-column {
        border-bottom: 2px solid #333544;
      }
    }
    .img-container {
      &.mac {
        display: none;
      }
      &.license {
        img {
          width: 40%;
        }
      }
      &.app {
        height: 450px;
        .iphone {
          width: auto;
          top: 20px;
        }
      }
    }
  }
  .faq-container {
    .rows {
      .faq {
        .question {
          h3 {
            &::before {
              font-size: 30px;
              margin: 0 15px;
              height: 25px;
            }
          }
        }
        .answer {
          p {
            margin: 20px;
          }
          ol,
          ul {
            margin: 0 20px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 580px) {
  .main-section {
    background-image: none !important;
  }
  .main {
    width: 100%;
    margin: 0 auto;
  }
  h1 {
    font-size: 35px;
    width: 90%;
    margin: 150px auto 50px auto;
  }
  h2 {
    font-size: 30px;
    &.dot {
      width: 90%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  ol,
  ul {
    padding-left: 15px;
  }
  .btn {
    width: 95%;
    padding: 16px;
    text-align: center;
    &.btn1 {
      width: 100%;
      max-width: 300px;
      padding: 20px;

      text-align: center;
    }
  }
  .text-content {
    width: 90%;
    margin: 30px auto 0 auto;

    .img-container {
      &.license {
        img {
          width: 60%;
        }
      }
      &.app {
        .back_logo {
          width: 300px;
          margin: 0 auto;
        }

        .iphone {
          height: 380px;
          top: 30px;
          left: 50%;
          transform: translate(-50%, 0);
        }
      }
      &.suport {
        img {
          width: 202px;
          height: 337px;
        }
      }
    }
  }
  .faq-container {
    > p {
      width: 90%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
