@font-face {
  font-family: "Futura";
  src: url("futura_pt_bold.eot"); /* IE 9 Compatibility Mode */
  src: url("futura_pt_bold.eot?#iefix") format("embedded-opentype"),
    /* IE < 9 */ url("futura_pt_bold.woff2") format("woff2"),
    /* Super Modern Browsers */ url("futura_pt_bold.woff") format("woff"),
    /* Firefox >= 3.6, any other modern browser */ url("futura_pt_bold.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("futura_pt_bold.svg#futura_pt_bold")
      format("svg"); /* Chrome < 4, Legacy iOS */
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Futura";
  src: url("futura_pt_bold_oblique.eot"); /* IE 9 Compatibility Mode */
  src: url("futura_pt_bold_oblique.eot?#iefix") format("embedded-opentype"),
    /* IE < 9 */ url("futura_pt_bold_oblique.woff2") format("woff2"),
    /* Super Modern Browsers */ url("futura_pt_bold_oblique.woff")
      format("woff"),
    /* Firefox >= 3.6, any other modern browser */
      url("futura_pt_bold_oblique.ttf") format("truetype"),
    /* Safari, Android, iOS */
      url("futura_pt_bold_oblique.svg#futura_pt_bold_oblique") format("svg"); /* Chrome < 4, Legacy iOS */
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Futura";
  src: url("futura_pt_book.eot"); /* IE 9 Compatibility Mode */
  src: url("futura_pt_book.eot?#iefix") format("embedded-opentype"),
    /* IE < 9 */ url("futura_pt_book.woff2") format("woff2"),
    /* Super Modern Browsers */ url("futura_pt_book.woff") format("woff"),
    /* Firefox >= 3.6, any other modern browser */ url("futura_pt_book.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("futura_pt_book.svg#futura_pt_book")
      format("svg"); /* Chrome < 4, Legacy iOS */
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Futura";
  src: url("futura_pt_book_oblique.eot"); /* IE 9 Compatibility Mode */
  src: url("futura_pt_book_oblique.eot?#iefix") format("embedded-opentype"),
    /* IE < 9 */ url("futura_pt_book_oblique.woff2") format("woff2"),
    /* Super Modern Browsers */ url("futura_pt_book_oblique.woff")
      format("woff"),
    /* Firefox >= 3.6, any other modern browser */
      url("futura_pt_book_oblique.ttf") format("truetype"),
    /* Safari, Android, iOS */
      url("futura_pt_book_oblique.svg#futura_pt_book_oblique") format("svg"); /* Chrome < 4, Legacy iOS */
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Futura";
  src: url("futura_pt_demi.eot"); /* IE 9 Compatibility Mode */
  src: url("futura_pt_demi.eot?#iefix") format("embedded-opentype"),
    /* IE < 9 */ url("futura_pt_demi.woff2") format("woff2"),
    /* Super Modern Browsers */ url("futura_pt_demi.woff") format("woff"),
    /* Firefox >= 3.6, any other modern browser */ url("futura_pt_demi.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("futura_pt_demi.svg#futura_pt_demi")
      format("svg"); /* Chrome < 4, Legacy iOS */
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Futura";
  src: url("futura_pt_demi_oblique.eot"); /* IE 9 Compatibility Mode */
  src: url("futura_pt_demi_oblique.eot?#iefix") format("embedded-opentype"),
    /* IE < 9 */ url("futura_pt_demi_oblique.woff2") format("woff2"),
    /* Super Modern Browsers */ url("futura_pt_demi_oblique.woff")
      format("woff"),
    /* Firefox >= 3.6, any other modern browser */
      url("futura_pt_demi_oblique.ttf") format("truetype"),
    /* Safari, Android, iOS */
      url("futura_pt_demi_oblique.svg#futura_pt_demi_oblique") format("svg"); /* Chrome < 4, Legacy iOS */
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Futura";
  src: url("futura_pt_extra_bold.eot"); /* IE 9 Compatibility Mode */
  src: url("futura_pt_extra_bold.eot?#iefix") format("embedded-opentype"),
    /* IE < 9 */ url("futura_pt_extra_bold.woff2") format("woff2"),
    /* Super Modern Browsers */ url("futura_pt_extra_bold.woff") format("woff"),
    /* Firefox >= 3.6, any other modern browser */
      url("futura_pt_extra_bold.ttf") format("truetype"),
    /* Safari, Android, iOS */
      url("futura_pt_extra_bold.svg#futura_pt_extra_bold") format("svg"); /* Chrome < 4, Legacy iOS */
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Futura";
  src: url("futura_pt_extra_bold_oblique.eot"); /* IE 9 Compatibility Mode */
  src: url("futura_pt_extra_bold_oblique.eot?#iefix")
      format("embedded-opentype"),
    /* IE < 9 */ url("futura_pt_extra_bold_oblique.woff2") format("woff2"),
    /* Super Modern Browsers */ url("futura_pt_extra_bold_oblique.woff")
      format("woff"),
    /* Firefox >= 3.6, any other modern browser */
      url("futura_pt_extra_bold_oblique.ttf") format("truetype"),
    /* Safari, Android, iOS */
      url("futura_pt_extra_bold_oblique.svg#futura_pt_extra_bold_oblique")
      format("svg"); /* Chrome < 4, Legacy iOS */
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Futura";
  src: url("futura_pt_heavy.eot"); /* IE 9 Compatibility Mode */
  src: url("futura_pt_heavy.eot?#iefix") format("embedded-opentype"),
    /* IE < 9 */ url("futura_pt_heavy.woff2") format("woff2"),
    /* Super Modern Browsers */ url("futura_pt_heavy.woff") format("woff"),
    /* Firefox >= 3.6, any other modern browser */ url("futura_pt_heavy.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("futura_pt_heavy.svg#futura_pt_heavy")
      format("svg"); /* Chrome < 4, Legacy iOS */
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Futura";
  src: url("futura_pt_heavy_oblique.eot"); /* IE 9 Compatibility Mode */
  src: url("futura_pt_heavy_oblique.eot?#iefix") format("embedded-opentype"),
    /* IE < 9 */ url("futura_pt_heavy_oblique.woff2") format("woff2"),
    /* Super Modern Browsers */ url("futura_pt_heavy_oblique.woff")
      format("woff"),
    /* Firefox >= 3.6, any other modern browser */
      url("futura_pt_heavy_oblique.ttf") format("truetype"),
    /* Safari, Android, iOS */
      url("futura_pt_heavy_oblique.svg#futura_pt_heavy_oblique") format("svg"); /* Chrome < 4, Legacy iOS */
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Futura";
  src: url("futura_pt_light.eot"); /* IE 9 Compatibility Mode */
  src: url("futura_pt_light.eot?#iefix") format("embedded-opentype"),
    /* IE < 9 */ url("futura_pt_light.woff2") format("woff2"),
    /* Super Modern Browsers */ url("futura_pt_light.woff") format("woff"),
    /* Firefox >= 3.6, any other modern browser */ url("futura_pt_light.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("futura_pt_light.svg#futura_pt_light")
      format("svg"); /* Chrome < 4, Legacy iOS */
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Futura";
  src: url("futura_pt_light_oblique.eot"); /* IE 9 Compatibility Mode */
  src: url("futura_pt_light_oblique.eot?#iefix") format("embedded-opentype"),
    /* IE < 9 */ url("futura_pt_light_oblique.woff2") format("woff2"),
    /* Super Modern Browsers */ url("futura_pt_light_oblique.woff")
      format("woff"),
    /* Firefox >= 3.6, any other modern browser */
      url("futura_pt_light_oblique.ttf") format("truetype"),
    /* Safari, Android, iOS */
      url("futura_pt_light_oblique.svg#futura_pt_light_oblique") format("svg"); /* Chrome < 4, Legacy iOS */
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Futura";
  src: url("futura_pt_medium.eot"); /* IE 9 Compatibility Mode */
  src: url("futura_pt_medium.eot?#iefix") format("embedded-opentype"),
    /* IE < 9 */ url("futura_pt_medium.woff2") format("woff2"),
    /* Super Modern Browsers */ url("futura_pt_medium.woff") format("woff"),
    /* Firefox >= 3.6, any other modern browser */ url("futura_pt_medium.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("futura_pt_medium.svg#futura_pt_medium")
      format("svg"); /* Chrome < 4, Legacy iOS */
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Futura";
  src: url("futura_pt_medium_oblique.eot"); /* IE 9 Compatibility Mode */
  src: url("futura_pt_medium_oblique.eot?#iefix") format("embedded-opentype"),
    /* IE < 9 */ url("futura_pt_medium_oblique.woff2") format("woff2"),
    /* Super Modern Browsers */ url("futura_pt_medium_oblique.woff")
      format("woff"),
    /* Firefox >= 3.6, any other modern browser */
      url("futura_pt_medium_oblique.ttf") format("truetype"),
    /* Safari, Android, iOS */
      url("futura_pt_medium_oblique.svg#futura_pt_medium_oblique") format("svg"); /* Chrome < 4, Legacy iOS */
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
